import React from 'react'
import { Container, Typography, Img } from 'components'
import { useTime } from 'context'
import { capitalizeFirstLetter } from 'utils'
import VideoWrapper from './VideoWrapper'

function LastTicketsPanel({
  lastCalledAttentions,
  attentionsById,
  currentAttention,
  isNextCallsMode,
}) {
  function calcFontSize(text) {
    if (text?.length > 20) {
      return `4vmin`
    } else if (text?.length >= 15) {
      return `6vmin`
    } else {
      return `8vmin`
    }
  }
  return (
    <Container
      width="35vw"
      height="100vh"
      backgroundColor="primary.4"
      justifyContent="center"
      alignContent="flex-start"
    >
      <Container
        justifyContent="center"
        backgroundColor="primary.1@0.6"
        width="100%"
        height="10vh"
        alignItems="center"
        textOverflow="ellipsis"
      >
        <Typography
          color="white"
          textOverflow="ellipsis"
          fontSize="5vh"
          fontWeight="light"
          fontStyle="italic"
        >
          Llamando a
        </Typography>
      </Container>
      <Container
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flexWrap="no-wrap"
        height="25vh"
      >
        {currentAttention ? (
          <>
            <Typography
              color="white"
              fontSize="13vmin"
              fontWeight="bold"
              lineHeight="1"
            >
              {currentAttention.longIdentifier}
            </Typography>
            <Typography
              color="white"
              fontSize={calcFontSize(currentAttention.moduleLongIdentifier)}
              fontWeight="bold"
              lineHeight="1"
            >
              {currentAttention.moduleLongIdentifier}
            </Typography>
          </>
        ) : (
          <Typography
            marginY="5"
            color="white"
            fontSize={{ _: 5, lg: 6 }}
            fontWeight="light"
          >
            Esperando llamados
          </Typography>
        )}
      </Container>
      <Container
        justifyContent="center"
        backgroundColor="primary.1@0.6"
        width="100%"
        height="10vh"
        alignItems="center"
      >
        <Typography
          color="white"
          fontSize="5vh"
          fontWeight="light"
          fontStyle="italic"
        >
          {isNextCallsMode ? `Próximos` : `Últimos`} llamados
        </Typography>
      </Container>
      <Container
        alignContent="flex-start"
        justifyContent="center"
        alignItems="flex-start"
        width="100%"
        height="55vh"
      >
        {lastCalledAttentions.map((id) => {
          const { shortIdentifier, moduleShortIdentifier } = attentionsById[id]
          return (
            <Container
              justifyContent="space-between"
              key={id}
              paddingX="3"
              height="10vh"
              width="100%"
              flexWrap="no-wrap"
            >
              <Typography
                color="white"
                fontWeight="bold"
                fontSize="8vh"
                marginRight="4"
              >
                {shortIdentifier}
              </Typography>
              {isNextCallsMode ? null : (
                <Typography
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  color="white"
                  fontWeight="100"
                  fontSize="8vh"
                >
                  {`${moduleShortIdentifier}`}
                </Typography>
              )}
            </Container>
          )
        })}
      </Container>
    </Container>
  )
}

function FooterTV({ logo }) {
  const currentTime = useTime()
  return (
    <Container
      width="100%"
      backgroundColor="white"
      overflow="hidden"
      zIndex={9999}
    >
      <Container
        padding="4"
        width="100%"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Img width="20%" src={logo} />
        <Typography color="primary.4" fontSize={{ _: 5, lg: 6 }}>
          {capitalizeFirstLetter(
            currentTime.format(`dddd, DD [ de ] MMMM YYYY, HH:mm:ss  [hrs.]`),
          )}
        </Typography>
      </Container>
    </Container>
  )
}

function TV({
  unit,
  logo,
  attentionsById,
  lastCalledAttentions,
  currentAttention,
  config,
  isNextCallsMode,
}) {
  return (
    <Container height="100vh" width="100vw" overflow="hidden">
      <Container
        width="65vw"
        height="100vh"
        flexDirection="column"
        justifyContent="space-between"
      >
        <VideoWrapper
          unit={unit}
          videoUrl={config?.videoUrl}
          currentAttention={currentAttention}
        />
        <FooterTV logo={logo} />
      </Container>
      <LastTicketsPanel
        lastCalledAttentions={lastCalledAttentions}
        attentionsById={attentionsById}
        currentAttention={currentAttention}
        isNextCallsMode={isNextCallsMode}
      />
    </Container>
  )
}

export default TV
