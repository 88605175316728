import axios from 'axios'
import { POS_SALE_URL } from 'config/constants'

export async function sale({
  type,
  amount,
  integrationId = null,
  posType,
  corporationId,
  patientRut,
  patientRutDv,
  financerCode = `privatePayment`,
}) {
  // export async function sale() {
  let response
  try {
    // eslint-disable-next-line max-len
    response = await axios.post(POS_SALE_URL, {
      type,
      amount,
      integrationId,
      posType,
      corporationId,
      patientRut,
      patientRutDv,
      financerCode,
    })
    // response = {
    //   data: {
    //     success: true,
    //     result: {
    //       sale: {
    //         COMERC: ``,
    //         SUCUR: `0001`,
    //         CAJA: `0001`,
    //         FECHA: `20220926`,
    //         HORA: `122002`,
    //         NROOPE: `001887`,
    //         NROSEC: `00`,
    //         BOLETA: `0000001887`,
    //         CSERVI: `TBK`,
    //         FAMTRX: `DEBITO`,
    //         CINSTR: `TBKVED`,
    //         BINTRX: ``,
    //         TXCRET: `00`,
    //         TXCGLO: `Transaccion%20Aprobada`,
    //         AUTORI: `189877%20B`,
    //         AUTCOD: `189877%20B`,
    //         AUTRET: `001`,
    //         AUTGLO: `%20APROBADO`,
    //         FECTBK: `220922`,
    //         HORTBK: `121100`,
    //         TERMID: `S4UCSANCARLOS002`,
    //         MONTO: `38033`,
    //         TARJETAHABIENTE: `PRUEBAS%2FDEBITO003`,
    //         GMARCA: `DB`,
    //         MARCA: `DB`,
    //         ULT4DIG: `3331`,
    //         MONVUE: ``,
    //         MONCUO: ``,
    //         NUMSEC: `001018002`,
    //         NUNICO: `00010001202209220018870000`,
    //         NUMUNI: `00010001202209220018870000`,
    //         PRODUCTO: `0`,
    //         NUMCUO: `00`,
    //         TIPOVOUCHER: `0`,
    //eslint-disable-next-line max-len
    //         VOUCHER: `%20%20%20%20%20%20%20%20%20%20%20%20%20%20VENTA%20DEBITO%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20%20MULTICARD%20S.A.%0D%0A%20%20%20%20%20%20JULIO%20PRADO%201191,%20PROVIDENCIA%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20SANTIAGO%0D%0A%20%20%20%20597040001103%20-%20S4UCSANCARLOS002%0D%0A%0D%0A22%2F09%2F22%2012%3A11%3A00%20%20B-DB%20************3331%0D%0AFECHA%20CONTABLE%20%20%20%20%20%20%20%20%20%3A%20%20%20%20%20%20%20%20%20%20%2F%20%20%2F%20%20%0D%0AMONTO%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20$%20%20%20%20%20%20%20%20%20%2040,216%0D%0ATOTAL%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20$%20%20%20%20%20%20%20%20%20%2040,216%0D%0AEMPLEADO%3A%20%20%200001%20%20%20%20%20BOLETA%3A%20%200000001887%0D%0ANUMERO%20OPERACION%20%20%20%20%20%20%20%3A%20%20%20%20%20%20%20001018002%0D%0ACODIGO%20AUTORIZACION%20%20%20%20%3A%20%20%20%20%20%20%20%20189877%20B%0D%0ANUMERO%20UNICO%20%2000010001202209220018870000%0D%0A%20ACEPTO%20PAGAR%20SEGUN%20CONTRATO%20CON%20EMISOR%0D%0A`,
    //         VOUCHERPREMIO: ``,
    //         ErrorCode: `00`,
    //         ErrorGlosa: ``,
    //       },
    //       confirmation: {
    //         TXCRET: `00`,
    //         TXCGLO: `OK`,
    //       },
    //     },
    //   },
    // }
  } catch (error) {
    return Promise.reject({ success: false, error: `Unexpected error` })
  }
  return response.data
}
