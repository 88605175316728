import { Container, Input, MaterialIcon } from 'components'
import API from 'config/api'
import { POS_TYPES, actionTypes } from 'config/constants'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { handleEmailMask, print } from 'utils'
import { handlePrints } from './DocumentObtainSelection'
import Feedbacks from './Feedbacks'
import { redirectWithoutQueuing, toExit } from './Redirection'

const getVoucherData = ({
  notifyResult,
  paymentResponse,
  scheduleData,
  posType,
  appointmentData,
}) => {
  if (posType === POS_TYPES.H2H && notifyResult) {
    return {
      raw: paymentResponse.result.sale.VOUCHER,
      appointmentData: appointmentData || { appointment: scheduleData },
    }
  }
  if (posType === POS_TYPES.H2H && !notifyResult) {
    return {
      raw: paymentResponse.result.sale.VOUCHER,
      privateInfo: scheduleData,
      appointmentData: appointmentData || { appointment: scheduleData },
    }
  }
  if (posType === POS_TYPES.INTEGRATED && scheduleData) {
    return { ...paymentResponse.result, ...scheduleData }
  }
}

export const handleSendDocuments = async ({
  email,
  locationState,
  history,
  setState,
  isEmailValid,
}) => {
  if (setState) {
    setState({ submitActivated: true })
  }
  if (isEmailValid || isEmailValid === undefined) {
    const {
      scheduleData,
      scheduleData: { unitId },
      paymentResponse,
      bonusResponse,
      bonusDocument,
      notifyResult,
      posType,
      data,
      appointmentData,
      getBonusResponse,
    } = locationState
    try {
      let voucherImage, bonusImage
      if (paymentResponse) {
        const voucherData = getVoucherData(locationState)
        const {
          data: { document: voucherDocument },
        } = await print({
          voucher: voucherData,
          isDocumentToSend: true,
          app: posType.toLowerCase(),
        })
        voucherImage = voucherDocument
      }
      if (bonusResponse) {
        const {
          data: { document: bonusDocument },
        } = await print({
          ...bonusResponse?.parsedResponse,
          isDocumentToSend: true,
          financerName: appointmentData?.financerName,
          getBonusResponse,
          app: `bonus`,
        })
        bonusImage = bonusDocument
      }
      await API.sendDocuments({
        email: email || scheduleData?.additionalPatientData?.patientData?.email,
        voucherImage,
        bonusImage,
        unitId,
        scheduleData,
        bonusDocument,
      })

      if (scheduleData.hasNotQueuing) {
        redirectWithoutQueuing({
          history,
          icon: <MaterialIcon icon="checkCircleOutline" />,
          iconColor: `green.0`,
          title: `Correo enviado
          exitosamente`,
          subtitle: `Espere por su atención.`,
        })
      } else {
        toExit({
          history,
          scheduleData: {
            ...scheduleData,
            financerCode: data?.financerCode,
            cardType: paymentResponse?.result?.sale?.FAMTRX,
          },
          isAutopagoFinished: true,
          paymentResponse,
          action: actionTypes.TO_ATTENTION,
          bonusResponse,
          documentsFlow: { flow: `email`, status: true },
        })
      }
    } catch (error) {
      await handlePrints({
        paymentResponse,
        bonusResponse,
        bonusDocument,
        notifyResult,
        scheduleData,
        posType,
        appointmentData,
      })
      if (scheduleData.hasNotQueuing) {
        redirectWithoutQueuing({
          history,
          icon: <MaterialIcon icon="errorOutline" />,
          iconColor: `primary.4`,
          title: `Fallo en el envío de sus documentos`,
          subtitle: `Espere por su atención.`,
        })
      } else {
        toExit({
          history,
          scheduleData: {
            ...scheduleData,
            financerCode: data?.financerCode,
            cardType: paymentResponse?.result?.sale?.FAMTRX,
          },
          isAutopagoFinished: true,
          paymentResponse,
          bonusResponse,
          action: actionTypes.TO_ATTENTION,
          documentsFlow: { flow: `email`, status: false },
        })
      }
    }
  }
}

const handleEnterEmail = ({ locationState, history }) => {
  const {
    scheduleData: { unitId },
  } = locationState
  history.push({
    pathname: `/totem/${unitId}/ingresarEmail`,
    state: locationState,
  })
}

const VerifyEmail = () => {
  const history = useHistory()
  const location = useLocation()
  const locationState = location?.state ?? {}
  const {
    scheduleData: {
      additionalPatientData: {
        patientData: { email },
      },
    },
  } = locationState
  const [isVisible, setIsVisible] = useState(false)
  const handleIsVisible = () => setIsVisible(!isVisible)

  return (
    <Feedbacks
      icon={<MaterialIcon icon="outlineEmail" />}
      iconColor="primary.4"
      iconPaddingTop="6vh"
      iconPaddingBottom="1.2vh"
      subtitle="Verifique su correo o indique nueva dirección"
      toExit={() => handleEnterEmail({ locationState, history })}
      textToExit="Modificar correo"
      textToRetry={
        <Container justifyContent="center" alignItems="center">
          <MaterialIcon icon="mail" fontSize="3vh" marginRight="1.8vh" />
          Enviar por correo
        </Container>
      }
      toRetry={() => handleSendDocuments({ locationState, history })}
      styleToRetry={{ marginTop: `2vh` }}
      extraMidContent={
        <Container width="100%" position="relative" alignItems="center">
          <Input
            width="100%"
            padding="3.7% 5%"
            fontSize="2.64vh"
            borderWidth="2px"
            borderColor="#D0D0D0"
            borderRadius="1vh"
            value={isVisible ? email : handleEmailMask(email)}
            style={{
              caretColor: `transparent`,
            }}
          />
          {email.split(`@`)[0].length > 3 ? (
            <MaterialIcon
              position="absolute"
              right="0"
              icon={isVisible ? `visibilityOff` : `visibility`}
              fontSize="3.3vh"
              marginRight="5%"
              onClick={handleIsVisible}
            />
          ) : null}
        </Container>
      }
    />
  )
}

export default VerifyEmail
